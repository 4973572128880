<template>
  <div>
    <!-- begin:: Header -->
    <div
      id="kt_header"
      ref="kt_header"
      class="header"
      v-bind:class="headerClasses"
    >
      <div
        class="container-fluid d-flex align-items-center justify-content-between"
      >
        <!-- begin:: Header Menu -->
        <div
          class="header-menu-wrapper header-menu-wrapper-left"
          ref="kt_header_menu_wrapper"
        >
          <div
            v-if="headerMenuEnabled"
            id="kt_header_menu"
            ref="kt_header_menu"
            class="header-menu header-menu-mobile"
            v-bind:class="headerMenuClasses"
          >
            <!-- example static menu here -->
            <ul
              class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
            >
              <template>
                <li class="breadcrumb-item">
                  <span style="font-size: 12px;">
                    TRANSAKSI BANK / BANK MASUK / <span style="font-weight: 600;">VOUCHER</span>
                  </span>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <!-- end:: Header Menu -->
        <!-- begin:: Header Topbar -->
        <div class="topbar">
          <!--begin: User Bar -->
          <div class="topbar-item">
            <!--begin: Notifications -->
            <ApprovalNotification></ApprovalNotification>
            <!--end: Notifications -->
            <div
              class="btn btn-icon w-auto d-flex align-items-center btn-lg px-2"
              id="kt_quick_user_toggle"
            >
              <span
                class="symbol symbol-30 symbol-circle symbol-xl-30 pr-4 symbol-light-success"
              >
                <img v-if="false" alt="Profile Photo" :src="currentUserPhoto" />
                <span
                  v-if="true"
                  class="symbol-label font-size-h5 font-weight-bold"
                >
                  {{ user_fullname.charAt(0).toUpperCase() }}
                </span>
              </span>
              <span
                class="d-none d-md-inline mr-12"
                v-bind:style="{
                  'font-family': 'lato',
                  'font-size': '12px',
                  'font-weight': '600'
                }"
              >
                <span class="font-weight-bold font-size-base d-md-inline mr-1">
                  HI,
                </span>
                {{ user_fullname }}
              </span>
              <i class="ki ki-arrow-down icon-xs text-muted mr-12"></i>
            </div>

            <div
              id="kt_quick_user"
              ref="kt_quick_user"
              class="offcanvas offcanvas-right p-10"
            >
              <!--begin::Header-->
              <div
                class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
              >
                <h3 class="font-weight-bold m-0">
                  PROFIL USER
                </h3>
                <a
                  href="#"
                  class="btn btn-xs btn-icon btn-light btn-hover-primary"
                  id="kt_quick_user_close"
                >
                  <i class="ki ki-close icon-xs text-muted"></i>
                </a>
              </div>
              <!--end::Header-->

              <!--begin::Content-->
              <perfect-scrollbar
                class="offcanvas-content pr-5 mr-n5 scroll"
                style="max-height: 90vh; position: relative;"
              >
                <!--begin::Header-->
                <div class="d-flex align-items-center mt-5">
                  <div class="symbol symbol-100 mr-5">
                    <img :src="currentUserPersonalInfo.photo" alt="" />
                    <i class="symbol-badge bg-success"></i>
                  </div>
                  <div class="d-flex flex-column">
                    <span class="font-weight-bold font-size-h5 text-dark-75">
                      {{ user_fullname }}
                    </span>
                    <div class="navi mt-2">
                      <span class="navi-link p-0 pb-2">
                        <span class="navi-icon mr-1">
                          <span class="svg-icon svg-icon-lg svg-icon-primary">
                            <!--begin::Svg Icon-->
                            <inline-svg src="assets/icons/Mail.svg" />
                            <!--end::Svg Icon-->
                          </span>
                        </span>
                        <span class="navi-text text-muted">
                          {{ email }}
                        </span>
                      </span>
                    </div>
                    <button
                      class="btn btn-light-primary btn-bold"
                      @click="onLogout"
                    >
                      SIGN OUT
                    </button>
                  </div>
                </div>
                <!--end::Header-->
                <div class="separator separator-dashed mt-8 mb-5"></div>
                <div class="separator separator-dashed my-7"></div>
              </perfect-scrollbar>
              <!--end::Content-->
            </div>
          </div>
          <!--end: User Bar -->
        </div>
        <!-- end:: Header Topbar -->
      </div>
    </div>
    <!-- end:: Header -->
    <!-- Body -->
    <div style="margin-bottom: 43px;">
      <div>
        <img class="mb-4" src="assets/background_page_title.png" alt="" style="height: 36px; margin-right: 10px;">
        <span style="font-size: 30px; font-weight: 700;">
          VOUCHER
        </span>
      </div>
      <div>
        <span>
          MENAMPILKAN DAN MEMBUAT DATA TRANSAKSI VOUCHER BARU
        </span>
      </div>
    </div>
    <div class="form-group">
      <template>
        <v-toolbar flat>
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="white"
                light
                class="mb-12"
                v-bind="attrs"
                v-on="on"
                style="text-transform: capitalize !important; min-height: 48px;"
              >
                BUAT TRANSAKSI BARU +
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-col>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >TANGGAL TRANSAKSI<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-menu
                          ref="date_voucher"
                          v-model="date_voucher"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          full-width
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formInputNotClear.bank_transaction_date"
                              class="vuetify-custom"
                              solo
                              v-bind:style="{
                                color: '#c30e0e !important'
                              }"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="bank_transaction_date"
                            no-title
                            @input="date_voucher = false"
                            @change="onChangeDate(bank_transaction_date)"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >PERUSAHAAN<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.workgroup_id"
                          id="workgroup_id"
                          :items="data_workgroup_combobox"
                          @click="dataWorkgroup_Combobox"
                          item-value="workgroup_id"
                          item-text="workgroup_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >PROYEK<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.project_id"
                          id="project_id"
                          :items="data_project_combobox"
                          @click="dataProject_Combobox"
                          item-value="project_id"
                          item-text="project_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >COST<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.financing_id"
                          id="financing_id"
                          :items="data_financings_combobox"
                          @click="dataFinancings_Combobox"
                          item-value="financing_id"
                          item-text="financing_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;">NO. FAKTUR PAJAK</span>
                      </v-col>
                      <v-col cols="9">
                        <v-text-field
                          v-model="formInput.tax_invoice_number"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;">NO. INVOICE</span>
                      </v-col>
                      <v-col cols="9">
                        <v-text-field
                          v-model="formInput.invoice_number"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;">NO. SURAT JALAN</span>
                      </v-col>
                      <v-col cols="9">
                        <v-text-field
                          v-model="formInput.street_mail_number"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;">DESKRIPSI TRANSAKSI</span>
                      </v-col>
                      <v-col cols="9">
                        <v-textarea
                          v-model="formInput.bank_transaction_desc"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >BANK<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.bank_id"
                          id="bank_id"
                          :items="data_bank_combobox"
                          @click="dataBank_Combobox"
                          item-value="bank_id"
                          item-text="bank_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >NOMOR REKENING<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-autocomplete
                          v-model="formInput.account_number"
                          id="account_number"
                          :items="data_bank_account_combobox"
                          @click="dataBankAccount_Combobox"
                          item-value="account_number"
                          item-text="account_number"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-8">
                        <span style="color: black; font-size: 14px;"
                          >NOMINAL<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <vuetify-money
                          v-model="formInput.amount"
                          v-bind:placeholder="placeholder"
                          v-bind:readonly="readonly"
                          v-bind:disabled="disabled"
                          v-bind:outlined="outlined"
                          v-bind:clearable="clearable"
                          v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                          v-bind:options="option"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  class="mb-4 btn btn-outline-secondary"
                  @click="close"
                >
                  BATALKAN
                </v-btn>
                <v-btn
                  color="primary"
                  light
                  class="mb-4"
                  @click="formSubmit"
                  style="text-transform: capitalize !important; color: white !important;"
                >
                  SIMPAN TRANSAKSI BARU
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogPaymentAccepted" max-width="800px">
            <v-card>
              <v-card-title>
                <span class="text-h5">PEMBAYARAN DITERIMA</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-col>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >TIPE PEMBAYARAN DITERIMA<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="add_voucher.payment_accepted"
                          id="payment_accepted_id"
                          :items="data_payment_accepted_combobox"
                          item-value="payment_accepted_id"
                          item-text="payment_accepted_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-8">
                        <span style="color: black; font-size: 14px;"
                          >NOMINAL<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <vuetify-money
                          v-model="formInput.amount"
                          v-bind:placeholder="placeholder"
                          v-bind:readonly="readonly"
                          v-bind:disabled="disabled"
                          v-bind:outlined="outlined"
                          v-bind:clearable="clearable"
                          v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                          v-bind:options="option"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  class="mb-4 btn btn-outline-secondary"
                  @click="closePaymentAccepted"
                >
                  BATALKAN
                </v-btn>
                <v-btn
                  color="primary"
                  light
                  class="mb-4"
                  @click="updatePaymentAccepted"
                  style="text-transform: capitalize !important; color: white !important;"
                >
                  SIMPAN
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="CARI VOUCHER"
            solo
            hide-details
            class="mb-12"
            style="width: 10px; box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);"
          ></v-text-field>
          <v-dialog
            v-model="dialogShowUploadEvidenceOfTransfer"
            max-width="800px"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-col>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;">
                          UPLOAD<span style="color: red;">*</span>
                        </span>
                      </v-col>
                      <v-col cols="9">
                        <v-file-input
                          accept="image/*,application/pdf"
                          type="file"
                          @change="onImageChangeEvidenceOfTransfer"
                          v-model="formInput.path_image_evidence_of_transfer"
                          show-size
                          counter
                          :clearable="false"
                        ></v-file-input>
                        <div
                          v-if="
                            this.add_voucher.path_image_evidence_of_transfer2 !=
                              null
                          "
                        >
                          <img
                            :src="add_voucher.path_image_evidence_of_transfer2"
                            style="max-width: 200px;"
                          />
                        </div>
                        <div v-else>
                          <img
                            :src="editedItem.path_image_evidence_of_transfer"
                            style="max-width: 200px;"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="dialogShowUploadEvidenceOfTransfer = false"
                >
                  TUTUP
                </v-btn>
                <v-btn
                  color="primary"
                  light
                  @click="formSubmit"
                  style="text-transform: capitalize !important; color: white !important;"
                >
                  SIMPAN
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogSeenEvidenceOfTransfer" max-width="800px">
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-col v-for="(data) in data_files" :key="data.bank_transaction_id">
                    <div v-if="data.path_image_evidence_of_transfer_extention == 'png' || data.path_image_evidence_of_transfer_extention == 'jpeg' || data.path_image_evidence_of_transfer_extention == 'jpg' || data.path_image_evidence_of_transfer_extention == 'gif'">
                      <div>
                        <span class="text-h6">FILE FOTO</span>
                      </div>
                      <div class="text-center">
                        <img :src="data.path_image_evidence_of_transfer" alt="Foto" style="max-width: 700px;" />
                      </div>
                    </div>
                    <div v-else-if="data.path_image_evidence_of_transfer_extention == 'pdf'">
                      <div>
                        <span class="text-h6">FILE PDF</span>
                      </div>
                      <div>
                        <a :href="data.path_image_evidence_of_transfer" target="_blank">LINK FILE PDF</a>
                      </div>
                    </div>
                  </v-col>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialogSeenEvidenceOfTransfer = false">
                  TUTUP
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >APAKAH YAKIN UNTUK MENGHAPUS DATA INI?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDelete">BATAL</v-btn>
                <v-btn
                  text
                  @click="deleteDataVoucher"
                  v-bind:style="{
                    color: '#1e2758 !important'
                  }"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="data_voucher"
          :item-key="bank_transaction_id"
          :options.sync="options"
          :search="search"
          sort-by="Id"
          class="elevation-1 mb-12"
          :loading="loadingTable"
          loading-text="HARAP TUNGGU..."
        >
          <template v-slot:[`item.number`]="{ index }">
            <td>
              {{
                options.page * options.itemsPerPage -
                  options.itemsPerPage +
                  (index + 1)
              }}
            </td>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <td>Rp {{ formatNumber(item.amount) }}</td>
          </template>
          <template v-slot:[`item.transaction_type`]="{ item }">
            <td>
              {{ item.transaction_type == "KBKM" ? "Kas Besar" : "Umum" }}
            </td>
          </template>
          <template v-slot:[`item.tax_invoice_number`]="{ item }">
            <td>
              {{
                item.tax_invoice_number === 'null' ? "-" : item.tax_invoice_number
              }}
            </td>
          </template>
          <template v-slot:[`item.invoice_number`]="{ item }">
            <td>
              {{ item.invoice_number === 'null' ? "-" : item.invoice_number }}
            </td>
          </template>
          <template v-slot:[`item.street_mail_number`]="{ item }">
            <td>
              {{
                item.street_mail_number === 'null' ? "-" : item.street_mail_number
              }}
            </td>
          </template>
          <template v-slot:[`item.path_image_evidence_of_transfer`]="{ item }">
            <v-btn
              color="blue"
              light
              small
              class="mr-3"
              @click="seenFileEvidenceOfTransfer(item)"
              data-toggle="tooltip"
              title="LIHAT"
              style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
              v-if="item.upload_status == 1"
            >
              <v-icon small color="white">
                  mdi-eye
                </v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.financing_name`]="{ item }">
            <td>
              {{ item.financing_name === null ? "-" : item.financing_name }}
            </td>
          </template>
          <template v-slot:[`item.posted_by`]="{ item }">
            <td>
              {{ item.posted_by === "" ? "-" : item.posted_by }}
            </td>
          </template>
          <template v-slot:[`item.date_posted`]="{ item }">
            <td>
              {{ item.date_posted === null ? "-" : item.date_posted }}
            </td>
          </template>
          <template v-slot:[`item.aksi`]="{ item }">
            <div v-if="user_role.toLowerCase() === 'atasan'">
              <!-- <v-btn
                color="green"
                light
                small
                class="mr-3"
                @click="editItem(item)"
                data-toggle="tooltip"
                title="Edit"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                v-if="item.status_receipt === 0"
              >
                <v-icon small color="white">
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                color="red"
                light
                small
                @click="deleteItem(item)"
                data-toggle="tooltip"
                title="Hapus"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                v-if="item.status_receipt === 0"
              >
                <v-icon small color="white">
                  mdi-delete
                </v-icon>
              </v-btn>
              <v-icon small color="black" v-if="item.status_receipt === 1">
                flaticon2-line
              </v-icon> -->
              <v-icon small color="black">
                flaticon2-line
              </v-icon>
            </div>
            <div v-else-if="user_role.toLowerCase() === 'atasan operasional'">
              <!-- <v-btn
                color="green"
                light
                small
                class="mr-3"
                @click="editItem(item)"
                data-toggle="tooltip"
                title="Edit"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                v-if="item.status_receipt === 0"
              >
                <v-icon small color="white">
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                color="red"
                light
                small
                @click="deleteItem(item)"
                data-toggle="tooltip"
                title="Hapus"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                v-if="item.status_receipt === 0"
              >
                <v-icon small color="white">
                  mdi-delete
                </v-icon>
              </v-btn>
              <v-icon small color="black" v-if="item.status_receipt === 1">
                flaticon2-line
              </v-icon> -->
              <v-icon small color="black">
                flaticon2-line
              </v-icon>
            </div>
            <div v-else>
              <v-btn
                color="blue"
                light
                small
                class="my-2"
                @click="uploadEvidenceOfTransfer(item)"
                data-toggle="tooltip"
                title="UPLOAD"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                v-if="
                  (user_role.toLowerCase() === 'keuangan' &&
                    item.post_status === 0 &&
                    item.upload_status === 0) ||
                    (user_role.toLowerCase() === 'atasan keuangan' &&
                      item.post_status === 0 &&
                      item.upload_status === 0) ||
                    (user_role.toLowerCase() === 'keuangan kas kecil' &&
                      item.post_status === 0 &&
                      item.upload_status === 0) ||
                    (user_role.toLowerCase() === 'master keuangan' &&
                      item.post_status === 0 &&
                      item.upload_status === 0)
                "
              >
                <v-span small style="color: white;" v-if="item.status == 2">
                  UPLOAD SURAT TRANSFER
                </v-span>
                <v-span small style="color: white;" v-else>
                  UPLOAD BUKTI TRANSFER
                </v-span>
              </v-btn>
              <v-btn
                color="blue"
                light
                small
                @click="updateDataVoucherEscrowAccepted(item)"
                data-toggle="tooltip"
                title="ESCROW DITERIMA"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                v-if="
                  item.status_receipt === 1 &&
                    item.post_status === 0 &&
                    item.status_escrow_accepted === 0 &&
                    item.payment_accepted === 'E'
                "
              >
                <v-span small style="color: #ffffff;">
                  ESCROW DITERIMA
                </v-span>
              </v-btn>
              <v-btn
                color="blue"
                light
                small
                @click="updateDataVoucherPostStatus(item)"
                data-toggle="tooltip"
                title="POSTING"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                v-if=" item.status_receipt === 1 && item.post_status === 0 && item.payment_accepted === 'M' && item.financing_id != null"
              >
                <v-span small style="color: #ffffff;">
                  POSTING
                </v-span>
              </v-btn>
              <v-btn
                color="blue"
                light
                small
                @click="updateDataVoucherPostStatus(item)"
                data-toggle="tooltip"
                title="POSTING"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                v-if="item.status_receipt === 1 && item.post_status === 0 && item.status_escrow_accepted === 1 && item.payment_accepted === 'E' && item.financing_id != null"
              >
                <v-span small style="color: #ffffff;">
                  POSTING
                </v-span>
              </v-btn>
              <v-btn
                color="blue"
                light
                small
                class="mb-2 mt-1"
                @click="paymentAcceptedItem(item)"
                data-toggle="tooltip"
                title="PEMBAYARAN DITERIMA"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                v-if="item.status_receipt === 0 && item.upload_status === 1 && item.financing_id != null"
              >
                <v-span small style="color: white;">
                  PEMBAYARAN DITERIMA
                </v-span>
              </v-btn>
              <v-btn
                color="green"
                light
                small
                class="mr-3"
                @click="editItem(item)"
                data-toggle="tooltip"
                title="EDIT"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                v-if="item.status_receipt === 0"
              >
                <v-icon small color="white">
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                color="red"
                light
                small
                @click="deleteItem(item)"
                data-toggle="tooltip"
                title="HAPUS"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                v-if="item.status_receipt === 0"
              >
                <v-icon small color="white">
                  mdi-delete
                </v-icon>
              </v-btn>
              <v-icon small color="black" v-if="item.post_status === 1">
                flaticon2-line
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </template>
    </div>
    <!-- End Body -->
  </div>
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<style scoped>
.vuetify-custom >>> .v-text-field__slot label {
  color: #1e2758 !important;
}

.vuetify-custom >>> .v-select__slot label {
  color: #1e2758 !important;
}

.vuetify-custom >>> .v-input__icon i {
  color: #1e2758 !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import ApprovalNotification from "@/view/pages/dashboard/ApprovalNotificationBell.vue";
import Services from "@/core/services/app_internal/Services";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import "@/core/plugins/vuetify-money.js";
import { formatNumber } from "@/helpers/helper.js";
import { formatDate } from "@/helpers/helper.js";
import localStorage from "@/core/services/store/localStorage";

export default {
  name: "bank-in",

  components: {
    ApprovalNotification
  },

  props: ["bankTransactionPermission"],

  data: () => ({
    search: "",
    dialog: false,
    dialogShowUploadEvidenceOfTransfer: false,
    dialogPaymentAccepted: false,
    dialogDelete: false,
    dialogDeleteDetail: false,
    dialogSeenEvidenceOfTransfer: false,
    create_data_voucher: [],
    update_data_voucher: [],
    update_data_bank_guarantee: [],
    delete_data_voucher: [],
    data_voucher: [],
    data_escrow_notification: [],
    data_bank_guarantee_notification: [],
    data_submission_form_finance_notification: [],
    data_workgroup_combobox: [],
    data_project_combobox: [],
    data_financings_combobox: [],
    data_bank_combobox: [],
    data_bank_account_combobox: [],
    data_cheque_combobox: [],
    data_files: [],
    data_payment_accepted_combobox: [
      {
        payment_accepted_id: "E",
        payment_accepted_name: "Escrow"
      },
      {
        payment_accepted_id: "M",
        payment_accepted_name: "Masuk"
      }
    ],
    data_voucher_date: {
      bank_transaction_date: new Date().toISOString().substr(0, 10),
      date: new Date().toISOString().substr(0, 10)
    },
    add_voucher: {
      workgroup_id: "",
      workgroup_name: "",
      project_id: "",
      project_name: "",
      financing_id: "",
      financing_name: "",
      guarantee_id: "",
      tax_invoice_number: "",
      invoice_number: "",
      street_mail_number: "",
      bank_id: "",
      bank_name: "",
      account_number: 0,
      account_name: "",
      bank_transaction_desc: "",
      bank_transaction_date: new Date().toISOString().substr(0, 10),
      bank_transaction_type: "",
      transaction_type: "",
      amount: 0,
      path_image_evidence_of_transfer: null,
      path_image_evidence_of_transfer2: null
    },
    add_voucher_not_null: {
      date_voucher: false,
      bank_transaction_date: formatDate()
    },
    submitted: false,
    headers: [],
    editedIndex: -1,
    deletedIndex: -1,
    editedItem: {},
    upload_evidence_of_transfer: {},
    editedItemDetail: {},
    deletedItem: {},
    deletedItemDetail: {},
    defaultItem: {},
    isFirstLoad: true,
    options: {
      page: 1,
      itemsPerPage: 5,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: true,
      mustSort: false
    },
    label: "",
    placeholder: "",
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: false,
    valueWhenIsEmpty: "",
    option: {
      locale: "en-US",
      prefix: "",
      suffix: "",
      length: 20,
      precision: 0
    },
    bank_transaction_date: "",
    date: "",
    user_role: "",
    seen: "",
    bankTransactionId: "",
    count: "",
    user_id: "",
    user_fullname: "",
    email: "",
    count_escrow_notification: 0,
    count_bank_guarantee_notification: 0,
    count_submission_form_finance_notification: 0,
    loadingTable: true
  }),

  computed: {
    ...mapGetters([
      "layoutConfig",
      "getClasses",
      "currentUserPersonalInfo",
      "currentUserPhoto"
    ]),

    config() {
      return this.layoutConfig();
    },

    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    totalNotification: function() {
      let countEscrowNotification = this.count_escrow_notification;
      let countBankGuaranteeNotification = this
        .count_bank_guarantee_notification;
      let countSubmissionFormFinanceNotification = this
        .count_submission_form_finance_notification;
      let totalNotification = 0;

      totalNotification += parseInt(
        countEscrowNotification +
          countBankGuaranteeNotification +
          countSubmissionFormFinanceNotification
      );

      return totalNotification;
    },

    formTitle() {
      let result = "";

      if (this.editedIndex === -1) {
        result = "BUAT VOUCHER";
      } else if (this.editedIndex === -2) {
        result = "UBAH VOUCHER DETAIL";
      } else if (this.editedIndex === -3) {
        result = "UPLOAD BUKTI/SURAT TRANSFER";
      } else if (this.editedIndex === -4) {
        result = "BUKTI TRANSFER";
      } else {
        result = "UBAH VOUCHER";
      }

      return result;
    },

    formInput() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.add_voucher;
      } else if (this.editedIndex === -3) {
        result = this.upload_evidence_of_transfer;
      } else {
        result = this.editedItem;
      }

      return result;
    },

    formInputNotClear() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.add_voucher_not_null;
      } else {
        result = this.editedItem;
      }

      return result;
    },

    formSubmit() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.createDataVoucher;
      } else if (this.editedIndex === -3) {
        result = this.uploadDataEvidenceOfTransfer;
      } else {
        result = this.updateDataVoucher;
      }

      return result;
    },

    computedDate() {
      return this.formatDate(this.bank_transaction_date);
    },

    computedDateModified() {
      return this.formatDate(this.date);
    }
  },

  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );

    const headerRef = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach(item => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });

    this.load();

    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);

    this.user_id = localStorage.getLocalStorage("UID");
    this.email = localStorage.getLocalStorage("User_Email");
    this.user_role = localStorage.getLocalStorage("User_Role");
    this.user_fullname = localStorage.getLocalStorage("User_Fullname");

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Voucher" }]);
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogPaymentAccepted(val) {
      val || this.closePaymentAccepted();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },

    options: {
      async handler(val) {
        val;
        if (!this.isFirstLoad) await this.dataDoMasuk();
        this.loading = false;
      },
      deep: true
    },

    bank_transaction_date() {
      this.formInputNotClear.bank_transaction_date = this.formatDate(
        this.bank_transaction_date
      );
    },

    date() {
      this.formInputNotClear.date = this.formatDate(this.date);
    }
  },

  created() {
    this.initHeders();
  },

  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },

    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },

    createImage(file) {
      let reader = new FileReader();

      reader.onload = e => {
        this.add_voucher.path_image_transaction_type2 = e.target.result;
        this.count = this.add_voucher.path_image_transaction_type2.length;
      };

      reader.readAsDataURL(file);
    },

    onImageChange(file) {
      this.createImage(file);
    },

    createImageEvidenceOfTransfer(file) {
      let reader = new FileReader();

      reader.onload = e => {
        this.add_voucher.path_image_evidence_of_transfer2 = e.target.result;
        this.count = this.add_voucher.path_image_evidence_of_transfer2.length;
      };

      reader.readAsDataURL(file);
    },

    onImageChangeEvidenceOfTransfer(file) {
      this.createImageEvidenceOfTransfer(file);
    },

    getHeaders() {
      return [
        {
          text: "Id",
          sortable: false,
          value: "bank_transaction_id",
          align: " d-none"
        },
        { text: "NO", value: "number", width: "80px" },
        {
          text: "NO VOUCHER",
          sortable: true,
          value: "no_voucher",
          width: "150px"
        },
        {
          text: "TGL TRANSFER",
          sortable: true,
          value: "bank_transaction_date",
          width: "160px"
        },
        {
          text: "PERUSAHAAN",
          sortable: true,
          value: "workgroup_name",
          width: "150px"
        },
        {
          text: "PROYEK",
          sortable: true,
          value: "project_name",
          width: "130px"
        },
        {
          text: "COST",
          sortable: true,
          value: "financing_name",
          width: "120px"
        },
        {
          text: "NO FAKTUR PAJAK",
          sortable: true,
          value: "tax_invoice_number",
          width: "190px"
        },
        {
          text: "NO INVOICE",
          sortable: true,
          value: "invoice_number",
          width: "140px"
        },
        {
          text: "NO SURAT JALAN",
          sortable: true,
          value: "street_mail_number",
          width: "180px"
        },
        {
          text: "DESKRIPSI",
          sortable: true,
          value: "bank_transaction_desc",
          width: "140px"
        },
        {
          text: "BANK",
          sortable: true,
          value: "bank_name",
          width: "110px"
        },
        {
          text: "NO REKENING",
          sortable: true,
          value: "account_number",
          width: "150px"
        },
        {
          text: "NOMINAL",
          sortable: true,
          value: "amount",
          width: "130px"
        },
        {
          text: "BUKTI TRANSFER",
          sortable: true,
          value: "path_image_evidence_of_transfer",
          width: "180px"
        },
        {
          text: "DIPOSTING OLEH",
          sortable: true,
          value: "posted_by",
          width: "170px"
        },
        {
          text: "TANGGAL POSTING",
          sortable: true,
          value: "date_posted",
          width: "190px"
        },
        {
          text: "EDIT",
          value: "aksi",
          sortable: false,
          align: "center",
          width: "110px"
        }
      ];
    },

    loadDate: function() {
      this.bank_transaction_date = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.bank_transaction_date = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    loadDateModified: function() {
      this.date = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    formatDate(date) {
      if (!date) return null;

      var arr = date.split("-"),
        year = arr[0],
        month = arr[1],
        day = arr[2];
      return `${day}-${month}-${year}`;
    },

    parseDate: function(date) {
      if (!date) return null;

      var arr = date.split("/"),
        day = arr[0],
        month = arr[1],
        year = arr[2];
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    onChangeDate(item) {
      this.$refs.date_voucher.save(item);
      this.data_voucher_date.bank_transaction_date = item;
    },

    createDataVoucher() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "C",
          Token: "C",
          UID: localStorage.getLocalStorage("UID"),
          Route: "MUTATION",
          workgroup_id: this.add_voucher.workgroup_id,
          project_id: this.add_voucher.project_id,
          financing_id: this.add_voucher.financing_id,
          bank_id: this.add_voucher.bank_id,
          account_number: this.add_voucher.account_number,
          tax_invoice_number: this.add_voucher.tax_invoice_number,
          invoice_number: this.add_voucher.invoice_number,
          street_mail_number: this.add_voucher.street_mail_number,
          bank_transaction_desc: this.add_voucher.bank_transaction_desc,
          bank_transaction_date: this.data_voucher_date.bank_transaction_date,
          bank_transaction_permission: "BM",
          amount: this.add_voucher.amount,
          created_by: localStorage.getLocalStorage("User_Fullname"),
          approval_status: 0,
          upload_status: 0,
          status: 1
        };

        var postdata = new FormData();

        for (var key in mydata) {
          postdata.append(key, mydata[key]);
        }

        let contentType = `multipart/form-data; boundary=${postdata._boundary}`;

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          postdata,
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MENAMBAH DATA",
                icon: "success",
                heightAuto: false,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: "GAGAL MENAMBAH DATA",
                icon: "info",
                heightAuto: false,
                timer: 1500
              });
            }
            this.create_data_voucher = response.data;
            this.submitted = true;
            this.save("add_voucher");
            this.loadDataEscrowPaymentAccepted();
          },
          err => {
            err;
          }
        );
      });
    },

    createDataVoucherBankOut(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "C",
          Token: "C",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          workgroup_id: item.workgroup_id,
          project_id: item.project_id,
          bank_id: item.bank_id,
          account_number: item.account_number,
          bank_transaction_desc: "dari bank garansi",
          bank_transaction_date: this.data_voucher_date.date,
          bank_transaction_permission: "BK",
          transaction_type: "KBU",
          reference: "",
          amount: item.amount,
          path_image_transaction_type: "",
          created_by: localStorage.getLocalStorage("User_Fullname"),
          approval_status: 0,
          upload_status: 0,
          status: 1
        };

        var postdata = new FormData();

        for (var key in mydata) {
          postdata.append(key, mydata[key]);
        }

        let contentType = `multipart/form-data; boundary=${postdata._boundary}`;

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          postdata,
          contentType,
          response => {
            resolve(response.data);
            this.create_data_voucher = response.data;
            this.submitted = true;
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataVoucher() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          bank_transaction_id: this.editedItem.bank_transaction_id,
          no_voucher: this.editedItem.no_voucher,
          workgroup_id: this.editedItem.workgroup_id,
          project_id: this.editedItem.project_id,
          financing_id: this.editedItem.financing_id,
          bank_id: this.editedItem.bank_id,
          account_number: this.editedItem.account_number,
          tax_invoice_number: this.editedItem.tax_invoice_number,
          invoice_number: this.editedItem.invoice_number,
          street_mail_number: this.editedItem.street_mail_number,
          bank_transaction_desc: this.editedItem.bank_transaction_desc,
          bank_transaction_date: this.data_voucher_date.bank_transaction_date,
          bank_transaction_permission: "BM",
          amount: this.editedItem.amount,
          modified_by: localStorage.getLocalStorage("User_Fullname"),
          date_modified: this.data_voucher_date.date,
          status: this.editedItem.status
        };

        var postdata = new FormData();

        for (var key in mydata) {
          postdata.append(key, mydata[key]);
        }

        let contentType = `multipart/form-data; boundary=${postdata._boundary}`;

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          postdata,
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: false,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: "GAGAL MEMPERBAHARUI DATA",
                icon: "info",
                heightAuto: false,
                timer: 1500
              });
            }
            this.update_data_voucher = response.data;
            this.submitted = true;
            this.save("edit_voucher");
            this.loadDataEscrowPaymentAccepted();
          },
          err => {
            err;
          }
        );
      });
    },

    updatePaymentAccepted() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "PAYMENT_ACCEPTED",
          bank_transaction_id: this.editedItem.bank_transaction_id,
          payment_accepted: this.add_voucher.payment_accepted,
          date_receipt: this.data_voucher_date.date,
          amount: this.editedItem.amount,
          status_receipt: 1
        };

        var postdata = new FormData();

        for (var key in mydata) {
          postdata.append(key, mydata[key]);
        }

        let contentType = `multipart/form-data; boundary=${postdata._boundary}`;

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          postdata,
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: false,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: "GAGAL MEMPERBAHARUI DATA",
                icon: "info",
                heightAuto: false,
                timer: 1500
              });
            }
            this.update_data_voucher = response.data;
            this.submitted = true;
            this.save("edit_payment_accepted");
            this.loadDataEscrowPaymentAccepted();
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataVoucherPostStatus(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "POST_STATUS",
          bank_transaction_id: item.bank_transaction_id,
          posted_by: localStorage.getLocalStorage("User_Fullname"),
          date_posted: this.data_voucher_date.date,
          post_status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.update_data_voucher = response.data;
            this.submitted = true;
            this.save("edit_voucher");
            this.asyncCreateDataVoucherBankOut(item);
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataBankGuaranteeUploadStatus(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "UPLOAD_STATUS",
          guarantee_id: item.guarantee_id,
          upload_status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankGuarantee",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.update_data_bank_guarantee = response.data;
            this.submitted = true;
            this.save("edit_voucher");
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataVoucherEscrowAccepted(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "ESCROW_ACCEPTED",
          bank_transaction_id: item.bank_transaction_id,
          status_escrow_accepted: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.update_data_voucher = response.data;
            this.submitted = true;
            this.save("edit_voucher");
            this.loadDataEscrowPaymentAccepted();
          },
          err => {
            err;
          }
        );
      });
    },

    uploadDataEvidenceOfTransfer() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "UPLOAD_EVIDENCE_OF_TRANSFER",
          bank_transaction_id: this.upload_evidence_of_transfer
            .bank_transaction_id,
          destination: "evidence-of-transfer",
          path_image_evidence_of_transfer: this.upload_evidence_of_transfer
            .path_image_evidence_of_transfer,
          upload_status: 1
        };

        var postdata = new FormData();

        for (var key in mydata) {
          postdata.append(key, mydata[key]);
        }

        let contentType = `muntipart/forn-data; boundary=${postdata._boundary}`;

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          postdata,
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.update_data_internal = response.data;
            this.submitted = true;
            this.save("edit_internal");
            this.dialogShowUploadEvidenceOfTransfer = false;
            this.loadDataVoucher();
          },
          err => {
            err;
          }
        );
      });
    },

    deleteDataVoucher() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "D",
          Token: "D",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          bank_transaction_id: this.deletedItem.bank_transaction_id
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MENGHAPUS DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.delete_data_voucher = response.data;
            this.submitted = true;
            this.data_voucher.splice(this.deletedIndex, 1);
            this.closeDelete();
          },
          err => {
            err;
          }
        );
      });
    },

    seenEvidenceOfTransfer(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT_FILES_EVIDENCE_Of_TRANSFER",
          bank_transaction_id: item.bank_transaction_id
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_files = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataVoucher() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          bank_transaction_permission: this.bankTransactionPermission
            ? this.bankTransactionPermission
            : "BM"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_voucher = response.data;
            this.loadingTable = false;
          },
          err => {
            err;
          }
        );
      });
    },

    dataEscrowPaymentAccepted() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "ESCROW_PAYMENT_ACCEPTED",
          payment_accepted: "E",
          status_receipt: 1,
          status_escrow_accepted: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_escrow_notification = response.data;
            this.count_escrow_notification =
              this.user_role.toLowerCase() === "atasan keuangan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataBankGuaranteeNotification() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "NOTIFICATION_BANK_GUARANTEE",
          status_cashing: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankGuarantee",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bank_guarantee_notification = response.data;
            this.count_bank_guarantee_notification =
              this.user_role.toLowerCase() === "atasan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataSubmissionFormNotification() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "NOTIFICATION_FOR_BANK_GUARANTEE",
          submission_type: "BGOI",
          status_cashing: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_submission_form_finance_notification = response.data;
            this.count_submission_form_finance_notification =
              this.user_role.toLowerCase() === "atasan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataWorkgroup_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Workgroup",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_workgroup_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataProject_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX",
          workgroup_id: this.formInput.workgroup_id
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Project",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_project_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataBank_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Bank",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bank_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataBankAccount_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX",
          bank_id: this.formInput.bank_id
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/BankAccount",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bank_account_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataFinancings_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX",
          financing_type: "Cost"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Financings",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_financings_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    async load() {
      Promise.all([
        //await this.dataEscrowPaymentAccepted(),
        //await this.dataBankGuaranteeNotification(),
        //await this.dataSubmissionFormNotification(),
        await this.dataVoucher()
      ]).then(function(results) {
        results;
      });
    },

    async loadDataEscrowPaymentAccepted() {
      Promise.all([
        await this.dataEscrowPaymentAccepted(),
        await this.dataVoucher()
      ]).then(function(results) {
        results;
      });
    },

    async loadDataVoucher() {
      Promise.all([await this.dataVoucher()]).then(function(results) {
        results;
      });
    },

    async loadCombobox() {
      Promise.all([
        await this.dataWorkgroup_Combobox(),
        await this.dataProject_Combobox(),
        await this.dataBank_Combobox(),
        await this.dataBankAccount_Combobox(),
        await this.dataCheque_Combobox()
      ]).then(function(results) {
        results;
      });
    },

    async asyncCreateDataVoucherBankOut(item) {
      if (item.status == 2) {
        Promise.all([
          await this.createDataVoucherBankOut(item),
          await this.dataVoucher()
        ]).then(function(results) {
          results;
        });
      } else {
        Promise.all([await this.dataVoucher()]).then(function(results) {
          results;
        });
      }
    },

    uploadEvidenceOfTransfer(item) {
      this.editedIndex = -3;
      this.upload_evidence_of_transfer = Object.assign({}, item);
      this.bankTransactionId = item.bank_transaction_id;
      this.dialogShowUploadEvidenceOfTransfer = true;
    },

    seenFileEvidenceOfTransfer(item) {
      this.editedIndex = -4;
      this.seenEvidenceOfTransfer(item);
      this.dialogSeenEvidenceOfTransfer = true;
    },

    editItem(item) {
      this.editedIndex = this.data_voucher.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.loadCombobox();
      this.seen = item.bank_transaction_type;
      this.dialog = true;
    },

    paymentAcceptedItem(item) {
      this.editedIndex = this.data_voucher.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogPaymentAccepted = true;
    },

    deleteItem(item) {
      this.deletedIndex = this.data_voucher.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.data_voucher.splice(this.deletedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.add_voucher.workgroup_id = "";
        this.add_voucher.workgroup_name = "";
        this.add_voucher.project_id = "";
        this.add_voucher.project_name = "";
        this.add_voucher.financing_id = "";
        this.add_voucher.guarantee_id = "";
        this.add_voucher.tax_invoice_number = "";
        this.add_voucher.invoice_number = "";
        this.add_voucher.street_mail_number = "";
        this.add_voucher.bank_id = "";
        this.add_voucher.bank_name = "";
        this.add_voucher.account_number = 0;
        this.add_voucher.account_name = "";
        this.add_voucher.bank_transaction_desc = "";
        this.add_voucher.bank_transaction_type = "";
        this.add_voucher.transaction_type = "";
        this.add_voucher.amount = 0;
        this.add_voucher.path_image_evidence_of_transfer = null;
        this.add_voucher.path_image_evidence_of_transfer2 = null;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.upload_evidence_of_transfer = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closePaymentAccepted() {
      this.dialogPaymentAccepted = false;
      this.add_voucher.payment_accepted = "";
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deletedItem = Object.assign({}, this.defaultItem);
        this.deletedIndex = -1;
      });
    },

    save(formInput) {
      if (formInput == "add_voucher") {
        if (this.editedIndex > -1) {
          Object.assign(this.data_voucher[this.editedIndex], this.add_voucher);
        } else {
          this.data_voucher.push(this.add_voucher);
        }

        this.close();
      } else if (formInput == "edit_voucher") {
        if (this.editedIndex > -1) {
          Object.assign(this.data_voucher[this.editedIndex], this.editedItem);
        } else {
          this.data_voucher.push(this.editedItem);
        }

        this.close();
      } else if (formInput == "edit_payment_accepted") {
        if (this.editedIndex > -1) {
          Object.assign(this.data_voucher[this.editedIndex], this.editedItem);
        } else {
          this.data_voucher.push(this.editedItem);
        }

        this.closePaymentAccepted();
      } else if (formInput == "upload_evidence_of_transfer") {
        if (this.editedIndex > -1) {
          Object.assign(
            this.data_voucher[this.editedIndex],
            this.upload_evidence_of_transfer
          );
        } else {
          this.data_voucher.push(this.upload_evidence_of_transfer);
        }
      }
    },

    initHeders() {
      this.headers = this.getHeaders();
    },

    seenDetailType() {
      this.seen = this.formInput.bank_transaction_type;
    },

    formatNumber
  }
};
</script>
